import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/SEO/PageSeo'

const NotFoundPage = () => (
  <Layout>
    <Seo />
    <section className="error-page">
      <span>DSL</span>
      <h3>La page n'existe pas</h3>
      <Link to="/" className="btn">
        Retour page d'accueil
      </Link>
    </section>
  </Layout>
)

export default NotFoundPage
